<template>
  <div>
    <v-card>
      <v-card-title>
        Editor ✌
      </v-card-title>

      <v-card-text>
        <p class="small">Note: Use the form below to edit the questions.</p>

        <JsonEditor v-if="loaded"
          :options = "{  confirmText: 'confirm', cancelText: 'cancel'}"
          :objData="multimedia" 
          v-model="multimedia"
        />
      </v-card-text>
    </v-card>
    <v-btn @click="updateMultimedia()" color="primary" class="mt-10">Update</v-btn>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile";
const service = new RestResource();

export default {
  data() {
    return {
      loaded: false,
      multimedia: {}
    };
  },

  mounted() {
    this.fetchMultimedia();
  },

  methods: {
    fetchMultimedia() {
      let data = {
        _id: this.$route.params.multimediaId
      };
      service.fetchMultimedia(data).then(r => {
        this.multimedia = r.data.multimediaList[0];
        this.loaded = true
      });
    },
    updateMultimedia() {
      this.$setLoader()
      service.updateMultimedia(this.multimedia).then(r => {
        r;
        this.$showSuccessMessage(r.data)
      })
    }
  }
};
</script>